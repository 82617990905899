<template>
  <div class="container account-container">
    <div class="account-info-container">
      <span class="info-title"><span style="color: #e2136e;">Merchant </span> Account Info</span>
      <span class="info-details"> Merchant Account Number : <span
          class="info-details-val">{{
          stripCountryCode(accountInfo.wallet) ? stripCountryCode(accountInfo.wallet) : 'N?A'
        }}</span></span>
      <span class="info-details"> Merchant Account Name : <span
          class="info-details-val">{{ accountInfo.name ? accountInfo.name : 'N/A' }}</span></span>
      <span class="info-details"> Merchant Account Type : <span
          class="info-details-val">{{ accountInfo.merchantType ? accountInfo.merchantType : 'N/A' }}</span></span>
      <span class="info-details"> Merchant Account Email : <span
          class="info-details-val">{{ accountInfo.email ? accountInfo.email : 'N/A' }}</span></span>

      <div class="update-buttons">
        <email-update></email-update>
        <div class="update-merchant-info">
          <update-merchant-information :accountInfo="accountInfo">
          </update-merchant-information>
        </div>
      </div>

      <span class="note-text">
            Limit information of your account type can be found under <span style="color: #ffcb15; margin-top: 30px">info</span>
          </span>
    </div>
  </div>
</template>

<script>

import EmailUpdate from "./EmailUpdate";
import UpdateMerchantInformation from "@/components/accountInfo/UpdateMerchantInformation";

export default {
  name: "AccountInfo",
  components: {
    UpdateMerchantInformation,
    EmailUpdate,
  },
  data() {
    return {
      accountInfo: {}
    }
  },
  created() {
    this.loadAccountInfo();
  },
  methods: {
    loadAccountInfo() {
      this.$feedback.showLoading();
      this.$cppClient.post('profile/info', {})
          .then(({data}) => {
            this.$feedback.hideLoading();
            this.accountInfo = data;
          })
          .catch(({response}) => {
            this.$feedback.showFailed(response);
          });
    },
    stripCountryCode(shortCode) {
      if (shortCode && shortCode.slice(0, 1) === '+') {
        return shortCode = shortCode.substring(3);
      }
      return shortCode;
    },
  }
}
</script>

<style lang="scss" scoped>

$mobileW: 550px;
.account-container {
  display: flex;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 60px ;
  @media screen and (max-width: $mobileW) {
    margin-top: unset;
  }
}

.account-info-container {
  display: flex;
  width: 550px;
  flex-direction: column;
  border-radius: 5px;
  padding-left: 54px;
  padding-top: 22px;
  padding-bottom: 24px;
  background-color: #fcfcfc;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: $mobileW) {
    padding-left: 18px;
    padding-top: 16px;
  }

  .info-title {
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #707070;
    margin-bottom: 35px;
    @media (max-width: $mobileW) {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }

  .info-details {
    font-size: 21px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #95989a;
    word-break: break-all;
    font-weight: 500;
    margin-bottom: 15px;
    @media (max-width: $mobileW) {
      font-size: 13px;
      margin-bottom: 25px;
    }
  }

  .info-details-val {
    font-size: 18px;
    color: #707070;
    @media (max-width: $mobileW) {
      font-size: 11px;
    }
  }

  .note-text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #aaa;
    margin-top: 20px;
    @media (max-width: $mobileW) {
      font-size: 9px;
      margin-bottom: 5px;
    }
  }

  .update-buttons {
    display: flex;
  }

  .update-merchant-info {
    padding-left: 30px;
    @media (max-width: $mobileW) {
      font-size: 11px;
    }
  }
}

</style>
