<template>
  <div class="text-center">
    <v-dialog width="535" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="update-email-address-button" v-bind="attrs" v-on="on" @click="dialog = true" color="primary">
          Update Email Address
        </v-btn>
      </template>

      <v-card>
        <v-form v-model="isValid" ref="form" autocomplete="off">
          <div class="email-update-form">
            <span class="email-update-form-title"
            >Update Your
              <span style="color: #e2136e">Email Address</span></span
            >
            <v-text-field
                class="input-field-size"
                v-model="email"
                label="New Email Address"
                placeholder="Enter New Email"
                outlined
                :rules="emailRules"
                style="width: 80%"
                autocomplete="new-password"
                @paste.prevent
                required
            ></v-text-field>
            <v-text-field
                v-model="password"
                class="input-field-size"
                :rules="passwordRules"
                type="password"
                label="Password"
                style="width: 80%; margin-top: 15px"
                placeholder="Enter Your Password"
                autocomplete="new-password"
                required
                outlined
            ></v-text-field>
            <v-btn color="primary" @click="updateEmail" :disabled="!isValid"
            >Update Email
            </v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {EMAIL_RULE} from "cpp-js-util/CppUtil";
import encryptionLib from "cpp-js-util/encryption";

export default {
  name: "EmailUpdate",
  props: [],
  data() {
    return {
      dialog: false,
      email: "",
      password: "",
      isValid: true,
      passwordRules: [(v) => !!v || "Password is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => EMAIL_RULE.test(v) || "E-mail must be valid",
      ],
      autofilled: false
    }
  },
  methods: {
    async updateEmail() {
      if (!this.isValid) return
      try {
        let payload = {
          password: this.password,
          newEmail: this.email
        }

        let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback)
        payload.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, payload.password)

        this.$feedback.showLoading()
        await this.$cppClient.post("/profile/update/email/request", payload)

        this.$feedback.hideLoading()
        this.dialog = false
        await this.$feedback.showSuccessMessage(
            "Email update request successful, please check your email for verification."
        )
      } catch ({response}) {
        await this.$feedback.showFailed(response)
      }
    },
    getCancel() {
      this.$refs.form.reset()
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>

$mobileW: 550px;
@media (max-width: $mobileW) {
  .update-email-address-button {
    font-size: 11px;
  }
}

.email-update-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 84px;
  padding-bottom: 61px;

  @media (max-width: $mobileW) {
    padding-left: 18px;
    padding-bottom: 30px;
  }

  .email-update-form-title {
    font-size: 31px;
    margin-top: 44px;
    margin-bottom: 33px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    color: #707070;

    @media (max-width: $mobileW) {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}
</style>
