<template>
  <div class="text-center">
    <v-dialog width="650" v-model="modal">
      <template v-show="isMerchantPra" v-slot:activator="{ on, attrs }">
        <v-btn class="update-merchant-info-button" v-bind="attrs" v-on="on" @click="openModal" color="primary">
          Update Merchant Info
        </v-btn>
      </template>

      <v-card>
        <v-form v-model="isValid" ref="form" autocomplete="off">
          <div class="close-button-container">
            <v-icon
                class="close-button-icon"
                color="#000000"
                @click="closeModal">
              mdi-close
            </v-icon>
          </div>
          <div class="update-your-information-form">
            <span class="update-your-information-title">
              Update <span style="color: #e2136e">Merchant Info</span>
            </span>
            <div class="flex">
              <v-text-field
                  class="input-field-size display-name"
                  v-model="displayName"
                  :readonly="shouldDisable"
                  type="text"
                  label="Display Name"
                  placeholder="Change Your Display Name"
                  disabled
                  outlined
                  style="width: 80%"
                  @paste.prevent
                  required
              ></v-text-field>
              <div class="remove-wallet-button">
                <v-btn class="white--text" color="#ffcb15" :disabled="isWalletRemoved"
                       @click="removeWalletFromDisplayName">
                  Remove Wallet
                </v-btn>
              </div>
            </div>
            <v-text-field
                class="input-field-size"
                v-model="contactEmail"
                :rules="emailRules"
                type="email"
                label="Contact Email"
                placeholder="Enter Contact Email"
                outlined
                style="width: 80%"
                @paste.prevent
                required
            ></v-text-field>
            <v-text-field
                class="input-field-size"
                v-model="contactNumber"
                :rules="numberRules"
                type="tel"
                label="Contact Number"
                placeholder="Enter Contact Number"
                outlined
                style="width: 80%"
                @paste.prevent
                required
            ></v-text-field>
            <v-text-field
                class="input-field-size"
                v-model="password"
                placeholder="Enter Password"
                :rules="passwordRules"
                type="password"
                label="Password"
                outlined
                style="width: 80%"
                @paste.prevent
                required
            ></v-text-field>
            <v-btn color="primary" :disabled="!isValid" @click="updateInformation">
              Submit
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {EMAIL_RULE, PHONE_NUMBER_RULE} from "cpp-js-util/CppUtil";
import encryptionLib from "cpp-js-util/encryption";

const PHONE_NUMBER_SIZE = 11;
const PRA_MERCHANT = "PRA";

export default {
  name: "UpdateMerchantInformation",
  props: ['accountInfo'],
  created() {
  },
  data() {
    return {
      modal: false,
      displayName: "",
      contactEmail: "",
      contactNumber: "",
      password: "",
      merchantType: "",
      isValid: true,
      isWalletRemoved: false,
      shouldDisable: true,
      passwordRules: [(v) => !!v || "Password is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => EMAIL_RULE.test(v) || "E-mail must be valid",
      ],
      numberRules: [
        v => !!v || 'Number is required',
        v => (v && v.length === 11) || 'Number must have 11 digits',
        v => PHONE_NUMBER_RULE.test(v) || 'Provide valid phone number',
      ],
      autofilled: false,
    };
  },
  methods: {

    openModal() {
      this.setAccountInfo(this.accountInfo);
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    setAccountInfo(accountData) {
      this.displayName = accountData.displayName;
      this.contactEmail = accountData.contactEmail;
      this.contactNumber = accountData.contactNumber;
      this.merchantType = accountData.merchantType;
      this.isWalletRemoved = accountData.walletRemoved;
    },
    isMerchantPra() {
      return (this.merchantType === PRA_MERCHANT);
    },
    removeWalletFromDisplayName() {
      if (!this.isWalletRemoved) {
        const newDisplayName = this.displayName.split("_");
        if (newDisplayName[newDisplayName.length - 1].length >= PHONE_NUMBER_SIZE) {
          this.displayName = newDisplayName[0];
          this.isWalletRemoved = true;
        }
      }
    },
    async updateInformation() {
      if (!this.isValid) return;

      try {
        let payload = {
          displayName: this.displayName,
          contactEmail: this.contactEmail,
          contactNumber: this.contactNumber,
          password: this.password,
        };
        let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback);
        payload.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, payload.password);

        this.$feedback.showLoading();
        await this.$cppClient.post("/profile/update/contact-information", payload);
        this.$feedback.hideLoading();
        this.$feedback.showSuccessMessage(
            "Merchant Contact Information update request successful."
        );
      } catch ({response}) {
        this.$feedback.showFailed(response);
      }
    },
    getCancel() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>

$mobileW: 550px;
@media (max-width: $mobileW) {
  .update-merchant-info-button {
    font-size: 11px;
  }
}

.input-field-size {
  margin-bottom: 35px;
  height: 51px;
  display: inline-block;

  @media (max-width: $mobileW) {
    width: 100%;
    height: 41px;
  }
}

.update-your-information-form {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 84px;
  padding-bottom: 61px;

  @media (max-width: $mobileW) {
    padding-left: 18px;
    padding-bottom: 30px;
  }
}

.update-your-information-title {
  color: #707070;
  font-size: 29px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-bottom: 35px;
  margin-top: 40px;

  @media (max-width: $mobileW) {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.remove-wallet-button {
  width: 20%;
  padding-left: 7px;
  @media (max-width: $mobileW) {
    width: 100%;
    padding-left: 0;
    padding-bottom: 20px;
  }
}

.flex {
  display: flex;
  @media (max-width: $mobileW) {
    flex-direction: column;
  }
}

.close-button-container {
  display: flex;
  justify-content: end;
  padding-right: 3px;
  padding-top: 2px;
}

.display-name {
  width: 100% !important;
}

</style>
